import { useHistory, useLocation } from "react-router-dom";
import * as H from "history";
import { ApiException } from "../../gensrc/client";
import { ChildErrors } from "@react-typed-form/core";
import React, { useState, useEffect, ReactElement } from "react";
import {
  makeStyles,
  ButtonProps,
  CircularProgress,
  ThemeProvider,
  TextFieldProps,
  TextField,
} from "@material-ui/core";
import { BaseUrl } from "../shared/baseurl";

export function imageLink(imageId: string) {
  return `${BaseUrl}/api/ClubPack/image/${imageId}`;
}

export function useBackOrHome() {
  const history = useHistory();
  const location = useLocation<H.LocationDescriptorObject>();
  return () => {
    history.replace(location.state || { pathname: "/" });
  };
}

export function badRequestToErrors<T>(e: any): ChildErrors<T, string> {
  if (ApiException.isApiException(e) && e.status == 400) {
    const errors = JSON.parse(e.response) as object;
    const outErrors: ChildErrors<T> = {};
    Object.entries(errors).forEach(([a, b]) => {
      const k = a.substring(0, 1).toLowerCase() + a.substring(1);
      outErrors[k as keyof T] = b[0];
    });
    return outErrors;
  }
  throw e;
}

export default function useDebounce<V>(value: V, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

const useStyles = makeStyles((t) => ({
  progress: {
    color: t.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  progressWrapper: {
    margin: t.spacing(1),
    position: "relative",
  },
}));

export type ProgressButtonProps = { loading: boolean; children: ReactElement };

export function ProgressButton({ loading, children }: ProgressButtonProps) {
  const classes = useStyles();
  return (
    <div className={classes.progressWrapper}>
      {children}
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
}

export function FullWidthTextField(p: TextFieldProps) {
  return <TextField {...p} variant="outlined" fullWidth />;
}
