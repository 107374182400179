import React, { useEffect, useState, ReactElement } from "react";
import { render } from "react-dom";
import { BaseUrl, ApiUrl, StripeKey } from "../shared/baseurl";
import {
  BookingClient,
  BookingSlotDTO,
  AvailabilityDTO,
  BookingDTO,
} from "../../gensrc/client";
import { DateTime } from "luxon";
import TimeIcon from "@material-ui/icons/Schedule";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  ListSubheader,
  Container,
  Typography,
  Button,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  TextField,
  Grid,
} from "@material-ui/core";
import { useFormGroup, ControlDefs } from "@react-typed-form/core";
import {
  MUIFormControl,
  muiFieldRenderer,
  muiDateFieldRenderer,
} from "@react-typed-form/mui";
import { badRequestToErrors, ProgressButton } from "../shop/utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LuxonUtils from "@date-io/luxon";

const logoSrc = require("./logo.png");
const Path_Confirm = "/confirmed";
const Max_People = 10;
const stripePromise = loadStripe(StripeKey);

type DetailsForm = Omit<BookingDTO, "bookingSlotId" | "date">;
type FilterForm = {
  people: number;
  date: DateTime;
};

const detailsForm: ControlDefs<DetailsForm, MUIFormControl> = {
  emailAddress: { label: "Email Address", required: true },
  fullName: { label: "Name", required: true },
  mobileNumber: { label: "Mobile number", required: true },
  number: { label: "Number" },
};

const filterForm: ControlDefs<FilterForm, MUIFormControl> = {
  people: { label: "Number of people" },
  date: { label: "Date" },
};

const defaultFilter = { people: 2, date: DateTime.local() };

export function useApiClient<A>(f: new (baseUrl: string) => A) {
  return new f(ApiUrl);
}

function formatTime(iso: string) {
  return DateTime.fromISO(iso).toFormat("h:mm a");
}

function formatDate(iso: string) {
  return DateTime.fromISO(iso).toFormat("ccc, d MMM");
}

const useStyles = makeStyles((t) => ({
  times: { padding: t.spacing(1) },
  logo: {
    height: 100,
  },
  header: { display: "flex", flexDirection: "column", alignItems: "center" },
  field: { marginBottom: t.spacing(1) },
  numberOfPeople: { width: "10em" },
  confirmation: { margin: t.spacing(2) },
  filters: { display: "flex" },
  bookingText: { margin: `${t.spacing(1)}px 0` },
}));

function Main() {
  const bookingClient = useApiClient(BookingClient);
  const [availability, setAvailability] = useState<AvailabilityDTO[]>([]);
  const classes = useStyles();
  const stripe = useStripe();
  const { FormField, state, updateState } = useFormGroup(
    detailsForm,
    {
      emailAddress: "",
      mobileNumber: "",
      fullName: "",
      number: 1,
    },
    muiFieldRenderer
  );
  const [filters, setFilters] = useState(defaultFilter);
  const ff = useFormGroup(filterForm, defaultFilter, muiFieldRenderer, (a) =>
    setFilters(a.value)
  );
  const DateField = ff.useFieldRenderer(muiDateFieldRenderer);
  const [showBook, setShowBook] = useState(false);
  const [doingBooking, setDoingBooking] = useState(false);
  const [currentSlot, setCurrentSlot] = useState<{
    slot: BookingSlotDTO;
    date: string;
  }>();

  useEffect(() => {
    bookingClient
      .listBookingSlots(
        1,
        filters.date.toISODate(),
        undefined,
        undefined,
        undefined
      )
      .then((slots) => setAvailability(slots));
  }, [filters]);

  function cancelAndReload() {
    setShowBook(false);
    setFilters({ ...filters });
  }

  function bookSlot(slot: BookingSlotDTO, availability: AvailabilityDTO) {
    setCurrentSlot({ slot, date: availability.date });
    updateState({ value: state.current.initialValue });
    setShowBook(true);
  }

  function doBooking() {
    if (currentSlot) {
      setDoingBooking(true);
      const { slot, date } = currentSlot;
      bookingClient
        .addBooking({
          ...state.current.value,
          bookingSlotId: slot.id,
          number: filters.people,
          date,
          cancelUrl: BaseUrl,
          successUrl: BaseUrl + Path_Confirm,
        })
        .then(
          (sessionId) => {
            stripe!.redirectToCheckout({ sessionId });
            setShowBook(false);
            setDoingBooking(false);
          },
          (e) => {
            const errors = badRequestToErrors(e);
            updateState({ touched: true }, errors);
            setDoingBooking(false);
          }
        );
    }
  }

  function timeList(slots: BookingSlotDTO[], availability: AvailabilityDTO) {
    return (
      <List dense disablePadding>
        {slots.map((s) => (
          <ListItem key={s.id}>
            <ListItemIcon>
              <TimeIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${formatTime(s.startTime)} - ${formatTime(s.endTime)}`}
              secondary={`${s.availability} available`}
            ></ListItemText>
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                onClick={() => bookSlot(s, availability)}
                disabled={filters.people > s.availability}
              >
                Book
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }

  const numberError = state.current.children.number?.error;

  return (
    <>
      {currentSlot && (
        <Dialog open={showBook} onClose={() => setShowBook(false)}>
          <DialogTitle>Contact details</DialogTitle>
          <DialogContent>
            <Typography variant="body2" className={classes.bookingText}>
              We are taking booking deposits of $25 per head, this will be paid
              online while the booking is made. This deposit will be deducted
              from your bill the night you join us and act as a cancellation fee
              should you not show up.
            </Typography>
            <Typography variant="body2" className={classes.bookingText}>
              Please contact us with plenty of notice if you would like to
              change or cancel your reservation. Cancellations with less than 48
              hours notice will incur a cancellation fee equivalent to their
              deposit.
            </Typography>
            <Typography variant="subtitle2">
              Booking is for {filters.people} people.
            </Typography>
            <Typography variant="subtitle2">
              {formatDate(currentSlot.date)}
            </Typography>
            <Typography variant="subtitle2" className={classes.bookingText}>
              {formatTime(currentSlot.slot.startTime)} -{" "}
              {formatTime(currentSlot.slot.endTime)}
            </Typography>
            {numberError ? (
              <Typography variant="body1" color="error">
                {numberError}
              </Typography>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      field="emailAddress"
                      className={classes.field}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      field="mobileNumber"
                      className={classes.field}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      field="fullName"
                      className={classes.field}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="body2" className={classes.bookingText}>
                  Please be punctual. We will happily hold your table for up to
                  15 minutes after which time you may be charged a cancellation
                  fee equivalent to your deposit.
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={cancelAndReload}>
              Cancel
            </Button>
            <ProgressButton loading={doingBooking}>
              <Button color="primary" onClick={() => doBooking()}>
                Confirm
              </Button>
            </ProgressButton>
          </DialogActions>
        </Dialog>
      )}
      <div>
        <Typography
          variant="body2"
          align="center"
          className={classes.bookingText}
        >
          Due to the lifted restrictions we are no longer taking bookings, we
          are back to taking walk-ins with a capacity of 80.
          <br />
          Thanks for your continued support we can't wait to see you!
        </Typography>
        {/* <Typography
          variant="body2"
          align="center"
          className={classes.bookingText}
        >
          At the moment we cannot accomodate groups larger than 10 people.
          Children are welcome but bear in mind that they count toward the total
          number of people on the booking. Please note, no split bills and
          cashless payment only.
        </Typography>
        <Grid container spacing={1}>
          <Grid container item xs={12} justify="center">
            <Grid item xs={12} sm={6}>
              <ff.FormField field="people" select variant="filled" fullWidth>
                {Array.from({ length: Max_People }, (_, i) => {
                  return (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  );
                })}
              </ff.FormField>
            </Grid>
          </Grid>
          {availability.map((s) => (
            <Grid key={s.date} item xs={12} sm={6}>
              <List dense disablePadding>
                <ListSubheader>{formatDate(s.date)}</ListSubheader>
                {timeList(s.bookingSlots!, s)}
              </List>
            </Grid>
          ))}
        </Grid> */}
      </div>
    </>
  );
}

function Header(props: { children: ReactElement }) {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <div className={classes.header}>
        <img src={logoSrc} className={classes.logo} />
        <div>
          <Typography variant="h4" align="center">
            The Winston
          </Typography>
        </div>
      </div>
      {props.children}
    </Container>
  );
}

function Confirmed() {
  const classes = useStyles();
  return (
    <div className={classes.confirmation}>
      <Typography variant="body2" align="center">
        Thanks for your booking, if you need to contact us for any reason please
        email us at{" "}
        <a href="mailto:bookings@thewinstonbar.com">
          bookings@thewinstonbar.com
        </a>
        .
      </Typography>
    </div>
  );
}

render(
  <Elements stripe={stripePromise}>
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <BrowserRouter>
        <Header>
          <Switch>
            <Route exact path={Path_Confirm}>
              <Confirmed />
            </Route>
            <Route>
              <Main />
            </Route>
          </Switch>
        </Header>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Elements>,
  document.getElementById("app")
);
